
import { Welcome } from "./Welcome/index"
function App() {

  return (
    <Welcome />
  );
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./blueprint.css"
import "./fonts.css";
import { KeycloakWrapper } from '@squawkdirty/tapas-core';

ReactDOM.render(

    <App />
,
  document.getElementById('root')
);

